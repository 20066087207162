import './style.css'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useLocation,
} from 'react-router-dom'
import Fade from './utils/fade.jsx'

import Home from './home.jsx'
import Contact from './contact.jsx'
import Demos from './demos.jsx'
import Models from './models.jsx'
import { useState, useCallback, useEffect, useRef } from 'react'

const root = ReactDOM.createRoot(document.querySelector('#root'))

const originalConsoleWarn = console.warn
console.warn = function (message, ...args) {
  if (
    typeof message === 'string' &&
    (message.startsWith('Got NaN') || message.startsWith('THREE'))
  ) {
    return
  }
  originalConsoleWarn.call(console, message, ...args)
}

root.render(
  <>
    <Router>
      <ScrollToTop />

      <Nav />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />

        <Route path='/demos-1' element={<Demos />} />
        <Route path='/demos-2' element={<Demos />} />
        <Route path='/demos-3' element={<Demos />} />
        <Route path='/demos-4' element={<Demos />} />
        <Route path='/demos-5' element={<Demos />} />
        <Route path='/demos-6' element={<Demos />} />

        <Route path='/3Dmodels' element={<Models />} />
        <Route path='/3Dmodels-1' element={<Models />} />
        <Route path='/3Dmodels-2' element={<Models />} />
        <Route path='/3Dmodels-3' element={<Models />} />
        <Route path='/3Dmodels-4' element={<Models />} />
        <Route path='/3Dmodels-5' element={<Models />} />
        <Route path='/3Dmodels-6' element={<Models />} />
        <Route path='/3Dmodels-7' element={<Models />} />
        <Route path='/3Dmodels-8' element={<Models />} />
        <Route path='/3Dmodels-9' element={<Models />} />
        <Route path='/3Dmodels-10' element={<Models />} />
        <Route path='/3Dmodels-11' element={<Models />} />
        <Route path='/3Dmodels-12' element={<Models />} />
        <Route path='/3Dmodels-13' element={<Models />} />
        <Route path='/3Dmodels-14' element={<Models />} />
        <Route path='/3Dmodels-15' element={<Models />} />
        <Route path='/3Dmodels-16' element={<Models />} />
      </Routes>
    </Router>
  </>
)

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function Nav() {
  const [open, setOpen] = useState(false)
  const navbarRef = useRef(null) // Ref for the navbar

  const handleHamburger = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])
  const handleLinkClick = useCallback(() => {
    setTimeout(() => {
      setOpen(false)
    }, 100) // close navbar after 500ms
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If there is a click outside the navbar, close the navbar
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    // Cleanup function to remove the click listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className='navbar-wrapper' ref={navbarRef}>
        <Fade scroll={false}>
          <div className='navbar'>
            <NavLink
              to='/'
              className='circle navlink'
              exact='true'
              onClick={handleLinkClick}
            ></NavLink>
          </div>
          {/* <div className="navlink-wrapper">
                            <NavLink to="/demos-1" className="navlink" exact="true" onClick={handleLinkClick}>Interaction Demos</NavLink>
                        </div> */}
          {/* <div className="navlink-wrapper">
                            <NavLink to="/3Dmodels" className="navlink" exact="true" onClick={handleLinkClick}>3D Models</NavLink>
                        </div> */}
          {/* <div className="navlink-wrapper">
                            <NavLink to="/contact" className="navlink" exact="true" onClick={handleLinkClick}>Contact</NavLink>
                        </div> */}
        </Fade>
      </div>
    </>
  )
}
